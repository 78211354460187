import * as React from "react";
import Layout from "../../components/Navigation/layout";
import {useEffect, useState} from "react";
import {Client, handleApiError} from "../../services/ApiService";
import {Container} from "react-bootstrap";

const IndexPage = () => {
    const [wiederrufsrecht, setWiederrufsrecht] = useState('');

    useEffect(() => {
        Client.Raw.get('SiteConfig').then((res) => {
            setWiederrufsrecht(res.data[0].Wiederrufsrecht);
        }).catch(handleApiError);
    })

    useEffect(() => {
        if(window.location.href.includes('wiederrufsrecht')) {
            window.location.href = '/widerrufsrecht/';
        }
    }, [])

    return (
        <Layout pageTitle="Widerrufsrecht">
            <Container>
                <h2>Widerrufsrecht</h2>
                <div dangerouslySetInnerHTML={{__html: wiederrufsrecht}} />
            </Container>
        </Layout>
    )
}

export default IndexPage;
